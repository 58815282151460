import React, { Fragment } from 'react'
import Section from 'components/Layout/section'
import LinkButton from 'components/Buttons/LinkButton'
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  Row,
  Col
} from "reactstrap";
import projectData from 'assets/data/profile_page/projects.json'
import './project.scss'

function ProjectCard(props) {
  const { title, description, tags, links,
    image: {
      srcFile,
      isURL = false
    } } = props

  const tagJSX = tags.map((tag, key) => (
    <Fragment key={key}>
      <Badge key={key} color="info" pill>{tag}</Badge> {" "}
    </Fragment>
  ))

  const linksJSX = (
    <Row>
      {links.map((linkInfo, key) => (
        <Col key={key}>
          <LinkButton color="primary" href={linkInfo.href} external>{linkInfo.title}</LinkButton>
        </Col>
      ))
      }
    </Row>
  )

  return (
    <Card>
      <div className="card-wrapper">
        <div className="card-image">
          <img
            alt="..."
            className="img"
            src={isURL ? srcFile : require(`assets/img/projects/${srcFile}`)}
          />
        </div>
        <CardBody>
          <CardTitle tag="h4">{title}</CardTitle>
          <CardText>
            {description}
          </CardText>
          <CardText>
            <small className="text-muted">{tagJSX}</small>
          </CardText>
          <hr />
          {linksJSX.length !== 0 && (
            <div>
              <CardFooter>
                {linksJSX}
              </CardFooter>
            </div>
          )}
        </CardBody>
      </div>
    </Card>
  )
}

ProjectCard.defaultProps = {
  tags: [],
  links: []
}

function Project(props) {
  const projectCards = projectData.projects.map((project, index) => (
    <Col key={index}
      md="6">
      <ProjectCard
        {...project}
      />
    </Col>
  ))

  return (
    <Section dark container title="Project" center>
      <Row>
        {projectCards}
      </Row>
    </Section>
  )
}

export default Project