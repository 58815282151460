import React, { useState } from "react"
import { NavItem, NavLink, Nav } from "reactstrap";
import './navpills.scss'

function NavPills(props) {
  const { tabNames, onTabSelect, initialTab } = props
  const [activeTabIndex, setActiveTabIndex] = useState(initialTab)

  const handleTabClick = async (index) => {
    onTabSelect(index)
    await setActiveTabIndex(index)
  }

  const tabs = tabNames.map((name, index) => (
    <NavItem key={index}>
      <NavLink
        className={activeTabIndex === index ? "active" : ""}
        onClick={() => handleTabClick(index)}
        role="tablist"
      >
        {name}
      </NavLink>
    </NavItem>
  ))


  return (
    <div className="button_navpill_wrapper">
      <Nav className="nav-pills-primary" pills role="tablist">
        {tabs}
      </Nav>
    </div>
  )
}

NavPills.defaultProps = {
  initialTab: 0
}

export default NavPills