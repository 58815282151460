import React from 'react'
import classname from 'classnames'
import { Button } from 'reactstrap'

function CustomButton(props) {
  const { children, round, icon, simple, ...rest } = props
  const classes = classname({
    "btn-round": round,
    "btn-just-icon": icon,
    "btn-link": simple
  })
  const type = (!icon || !simple) ? "button" : ""
  return (
    <Button className={classes} type={type} {...rest}>
      {children}
    </Button>
  )
}

CustomButton.defaultProps = {
  round: false,
  icon: false,
  simple: false
}

export default CustomButton