import React from 'react'
import { Button } from 'reactstrap'

function LinkButton({ href, external, children, ...rest }) {
  let content = (
    <Button className="btn-link" {...rest}>
      {children}
    </Button>
  )
  if (external) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={href}>
        {content}
      </a>
    )
  }
  return content
}

LinkButton.defaultProps = {
  external: false
}

export default LinkButton