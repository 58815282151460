import React from "react";

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";

// core components
function HorizontalTabs(props) {
  const { tabInfo } = props
  const [activeTab, setActiveTab] = React.useState(0);

  const tabNavs = []
  const tabContents = []

  for (let index = 0; index < tabInfo.length; index++) {
    const tab = tabInfo[index]
    const { title, content: tabBody } = tab
    tabNavs.push((
      <NavItem className="pointer" key={index} onClick={() => {
        setActiveTab(index);
      }}>
        <NavLink
          className={activeTab === index ? "active" : ""}
        >
          {title}
        </NavLink>
      </NavItem>
    ))
    tabContents.push((
      <TabPane key={index} tabId={`tab${index}`}>
        {tabBody}
      </TabPane>
    ))
  }

  return (
    <Container className="tim-container">
      <div id="description-areas">
        <div className="nav-tabs-navigation">
          <div className="nav-tabs-wrapper">
            <Nav id="tabs" role="tablist" tabs>
              {tabNavs}
            </Nav>
          </div>
        </div>
        {/* Tab panes */}
        <TabContent className="text-center" activeTab={`tab${activeTab}`}>
          {tabContents}
        </TabContent>
      </div>
    </Container>
  );
}

export default HorizontalTabs;
