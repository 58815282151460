import React, { useState } from 'react'
import NavPills from 'components/Buttons/NavPills'
import Icon from 'components/Typography/Icon'
import Section from 'components/Layout/section'
import sectionData from 'assets/data/profile_page/skills.json'
import {
  Row,
  Col
} from "reactstrap";

function SkillCard(props) {
  const { name, icon } = props
  return (
    <div className="info skill-card-root">
      <div className="icon icon-neutral">
        <Icon name={icon} />
      </div>
      <div className="description">
        <h4 className="info-title">{name}</h4>
      </div>
    </div>
  )
}

const INITAL_SKILL_TAB = 1
function Skills() {
  const [currentTabIndex, setTabIndex] = useState(INITAL_SKILL_TAB)
  const { skills: skillsData } = sectionData
  const tabInfo = []
  for (let tabName of Object.keys(skillsData)) {
    const tabContent = skillsData[tabName]
    tabInfo.push({
      name: tabName,
      content: tabContent
    })
  }

  const handleTabSelect = index => {
    setTabIndex(index)
  }

  const buildTabContent = () => {
    const content = tabInfo[currentTabIndex].content.map((skillInfo, key) => (
      <SkillCard key={key} name={skillInfo.title} description={skillInfo.description} icon={skillInfo.icon_name} />
    ))
    return content
  }

  return (
    <Section dark center container title="Skills">
      <Row xs={1} sm={2} md={4}>
        <Col>
          <div className="mb-4">
            <NavPills initialTab={INITAL_SKILL_TAB} tabNames={tabInfo.map(tab => tab.name)} onTabSelect={handleTabSelect} />
          </div>
        </Col>
      </Row>
      <Row>
        {buildTabContent()}
      </Row>
    </Section>
  );
}

export default Skills