import React, { Fragment } from 'react'
import { Row, Col } from 'reactstrap'
import Section from 'components/Layout/section'
import sectionData from 'assets/data/profile_page/headline.json'

function Headline(propps) {
  return (
    <Fragment>
      <Row>
        <div className="profile-picture">
          <div
            className="fileinput fileinput-new"
            data-provides="fileinput"
          >
            <div className="fileinput-new img-no-padding">
              <img
                className="profile-img"
                alt="..."
                src={require("assets/img/avatar.jpeg")}
              />
            </div>
          </div>
        </div>
      </Row >
      <Section noTop>
        <div className="name">
          <h1 className="title mt-0 mb-0 text-center">
            {sectionData.title}
          </h1>
          <br />
          <h2 className="title mt-0 text-center">
            <small>{sectionData.subtitle}</small>
          </h2>
        </div>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="6">
            <p>
              {sectionData.summary}
            </p>
          </Col>
        </Row>
      </Section>
    </Fragment >
  )
}

export default Headline