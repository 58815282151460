import React, { Fragment } from 'react'
import classnames from 'classnames'
import {
  Container, Row,
  Col
} from 'reactstrap'

export default function Section(props) {
  const { dark, gray, center, container, title, noTop, children } = props
  const classes = {
    root: classnames("section", {
      "mt-0": noTop,
      "pt-0": noTop,
      "section-dark": dark,
      "section-gray": gray,
      "text-center": center
    })
  }
  let content = (
    <Fragment>
      {title && (
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <h2 className="section-title title">{title}</h2>
          </Col>
        </Row>
      )}
      {children}
    </Fragment>
  )
  if (container) {
    content = (
      <Container>
        {content}
      </Container>)
  }
  return <section className={classes.root}>{content}</section>
}

Section.defaultProps = {
  dark: false,
  gray: false,
  center: false,
  noTop: false
}