import React, { Fragment, useState } from 'react'
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Badge,
} from 'reactstrap'
import Button from 'components/Buttons/Button'
import data from 'assets/data/profile_page/institutions.json'
import Section from 'components/Layout/section'
import Tab from 'components/Tabs/HorizontalTabs'

function EduCard(props) {
  const { name, dateRange, focus, degree, imageSrc, isURL } = props
  return (
    <Card className="card-profile">
      <div className="card-avatar border-white">
        <img
          alt="..."
          src={isURL ? imageSrc : require(`assets/img/institutions/${imageSrc}`)}
        ></img>
      </div>
      <CardBody>
        <CardTitle tag="h4">{name}</CardTitle>
        <h5 className="card-title">{degree}</h5>
        <h6 className="card-category">{focus}</h6>
        {/* <p className="card-description">
          {description}
        </p> */}
      </CardBody>
      < hr />
      <CardFooter className="text-center">
        <small>{dateRange}</small>
      </CardFooter>
    </Card>
  )
}

function ExpCard(props) {
  const { companyName, dateRange, role, imageSrc, isURL, description, tags } = props
  return (
    <Card className="card-profile">
      <div className="card-avatar border-white">
        <img
          alt="..."
          src={isURL ? imageSrc : require(`assets/img/institutions/${imageSrc}`)}
        ></img>
      </div>
      <CardBody>
        <CardTitle tag="h4">{companyName}</CardTitle>
        <h6 className="card-category">{role}</h6>
        <p className="card-description ml-5 mr-5" style={{color: "#66615b", fontWeight: 400}}>
          {description}
        </p>
      </CardBody>
      <CardFooter className="text-center">
        {tags.length !== 0 && (
          <Fragment>
            <h5 className="subtitle">Skills Used</h5>
            {tags.map((tag, key) => (
              <Fragment key={key}>
                <Badge key={key} color="info" pill>{tag}</Badge>{" "}
              </Fragment>
            ))
            }
          </Fragment>
        )}
        < hr />
        <small>{dateRange}</small>
      </CardFooter>
    </Card>
  );
}

ExpCard.defaultProps = {
  tags: [],
  isURL: false
}

function TabContentView({ cardData, displayCard: RenderCard }) {
  const [viewAll, setViewAll] = useState(false)
  const handleViewMore = () => {
    setViewAll(true)
  }

  const cards = []
  if (viewAll) {
    for (let i = 0; i < cardData.length; i++) {
      cards.push(<RenderCard key={i} {...cardData[i]} />)
    }
  } else {
    cards.push(<RenderCard key="inital" {...cardData[0]} />)
  }
  return (
    <div>
      {cards}
      {!viewAll && (
        <div className="mt-5">
          <Button round onClick={handleViewMore}> View All </Button>
        </div>
      )}
    </div>
  )
}


function RootSection(props) {
  const tabInfo = [
    {
      title: "Experiences",
      content: <TabContentView key={"exp"} displayCard={ExpCard} cardData={data.experiences} />
    },
    {
      title: "Educations",
      content: <TabContentView key={'edu'} displayCard={EduCard} cardData={data.educations} />
    }
  ]
  return (
    <Section center container title="Experiences and Educations">
      <Tab tabInfo={tabInfo} />
    </Section>
  )
}

export default RootSection