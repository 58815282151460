import React from "react";
// reactstrap components
import { Button, Form, Input, Row, Col } from "reactstrap";
import Icon from "components/Typography/Icon";
import data from 'assets/data/profile_page/contact.json'
import Section from 'components/Layout/section'

function SocialMedia({ media, href }) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <Button className="btn-just-icon mr-1" color={media}>
        <Icon name={media} />
      </Button>
    </a>
  )
}

function ContactMe() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("contact-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
    };
  });

  const { header, socialMedia } = data

  return (
    <Section gray center container title={header.title} >
      <Row>
        <Col className="ml-auto mr-auto text-center" md="6">
          {/* <h3 className="title">
            <small>{socialMedia.sectionTitle}</small>
          </h3> */}
          {socialMedia.links.map((media, key) => {
            const mediaName = Object.keys(media)[0]

            return (
              <SocialMedia key={key} media={mediaName} href={media[mediaName]} />
            )
          })}
        </Col>
      </Row>
      {/* <Row>
        <Col className="ml-auto mr-auto text-center" md="6">
          <h3 className="title">
            <small>{data.email.sectionTitle}</small>
          </h3>
          <Form className="contact">
            <Row>
              <Col md="6">
                <Input placeholder="First Name" type="text" />
              </Col>
              <Col md="6">
                <Input placeholder="Last Name" type="text" />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Input placeholder="Email" type="text" />
              </Col>
              <Col md="6">
                <Input placeholder="Subject" type="text" />
              </Col>
            </Row>
            <Input placeholder="Message" rows="7" type="textarea" />
            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <Button block className="btn-round" color="primary">
                  Send
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row> */}
    </Section>
  );
}

export default ContactMe;