import React from 'react'
import { Switch, Route } from 'components/Router'
import Home from 'views/home'

function Router(props) {
  return (
    <Switch>
      <Route path="/" component={Home} />
    </Switch>
  )
}

function App(props) {
  return (
    <main>
      <Router />
    </main>
  )
}

export default App