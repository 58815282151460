import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faCode, faDatabase, faCircle } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faTwitter, faInstagram, faGit, faFacebook, faReact, faJs, faPython, faHtml5, faCss3, faAws, faDocker } from '@fortawesome/free-brands-svg-icons'

class IconFinder {
  // TODO: POPULATE ICON MAN
  static ICON_MAP = {
    "java": faCoffee,
    "twitter": faTwitter,
    "linkedin": faLinkedin,
    "instagram": faInstagram,
    "github": faGit,
    "facebook": faFacebook,
    "reactjs": faReact,
    "react-native": faReact,
    "expressjs": faJs,
    "flask": faPython,
    "c++": faCode,
    "python": faPython,
    "javascript": faJs,
    "html": faHtml5,
    "css": faCss3,
    "mongodb": faDatabase,
    "postgres": faDatabase,
    "aws": faAws,
    "docker": faDocker,
    "circleci": faCircle,
    "heroku": faCode
  }

  static get(iconName) {
    if (!IconFinder.ICON_MAP[iconName]) {
      return IconFinder.ICON_MAP['java']
    }
    return IconFinder.ICON_MAP[iconName]
  }
}

export default function Icon({ name }) {
  return <FontAwesomeIcon icon={IconFinder.get(name)} />
}