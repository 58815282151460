import React from 'react'
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
// SECTIONS
import Headline from './sections/headline'
import Skills from './sections/skills'
import Project from './sections/projects'
import Experiences from './sections/experiences';
import './index.scss'
import ContactMe from './sections/contact-me';
import Footer from "components/Footers/FooterBlack";
import { NavLink, NavItem } from 'reactstrap'

const SECTION_REF = {
  SKILLS: "skills",
  EXPERIENCES: "experiences",
  PROJECTS: "projects",
  CONTACT: "contact"
}

function SectionWrapper({ id, children }) {
  return (
    <div id={id}>
      {children}
    </div>
  )
}

const SectionNavItem = ({ title, href }) => (
  <NavItem className="active">
    <NavLink href={`#${href}`} onClick={e => {
      e.preventDefault()
      console.log(document.getElementById(href))
      document.getElementById(href).scrollIntoView({
        behavior: 'smooth'
      });
    }}>
      {title}
    </NavLink>
  </NavItem>
)

function Home(props) {
  const navItems = []
  for (let sectionName of Object.keys(SECTION_REF)) {
    navItems.push(
      <SectionNavItem key={sectionName} title={sectionName} href={SECTION_REF[sectionName]} />
    )
  }
  return (
    <div>
      <ColorNavbar navItems={navItems} />
      <ProfilePageHeader />
      <div className="wrapper">
        <div className="profile-content">
          <SectionWrapper>
            <Headline />
          </SectionWrapper>
          <SectionWrapper id={SECTION_REF.SKILLS}>
            <Skills />
          </SectionWrapper>
          <SectionWrapper id={SECTION_REF.EXPERIENCES}>
            <Experiences />
          </SectionWrapper>
          <SectionWrapper id={SECTION_REF.PROJECTS}>
            <Project />
          </SectionWrapper>
          <SectionWrapper id={SECTION_REF.CONTACT}>
            <ContactMe />
          </SectionWrapper>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home